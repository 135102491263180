import React, { ReactNode } from "react";
import { Link } from "gatsby";
import { Layout } from "StaticPages/Layouts";
import Helmet from "react-helmet";
import { PageSummary } from "StaticPages/Components/Commons/PageSummary";
import { BIDnaviBunner } from "StaticPages/Components/Commons/BIDnaviBunner";
import { useTranslation } from "react-i18next";
import { Box, Grid, Grow, Typography, useTheme } from "@mui/material";
import { css } from "@mui/material";
import { TransitionImage } from "StaticPages/Components/TransitionImage";
import { SkewAnimation } from "StaticPages/Components/Animations/SkewAnimation";
import { ScrollTrigger } from "mui-extensions/src/ScrollTrigger";
import { FadeAndSlideAnimation } from "StaticPages/Components/Animations/FadeAndSlideAnimation";
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from "constants";
import { ViewMoreButton } from "StaticPages/Components/Commons/ViewMoreButton";

export default () => {
    const { t } = useTranslation();
    return <>
        <Helmet title="サービス | VISUALIZ inc" />
        <Layout>
            <div>
                <Main />
            </div>
        </Layout>
    </>;
};

const Main = () => {
    const { t } = useTranslation();
    return <>
        <main>
            <PageSummary
                title={t("私たちのサービス")}
                description={<>
                    {t("VISUALIZの技術を結集した６つのサービス群。")}<br />
                    {t("未来への第一歩をサポートしていきます。")}
                </>}
                subTitle={t("SERVICE")}
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                src={require("../../../static/images//Service_Page_Header.jpg").default}
            />

            <Box
                sx={{
                    width: "100%"
                }}
            >
                <ServiceCollectionItem
                    title={t("AI")}
                    subTitle={t("AIシステム開発事業")}
                    detail={<>
                        {t("新しいWebサービスをお考えの方。")}<br />
                        {t("毎日大量にアップされるあらゆるWebサイトから新しく必要な情報だけをピックアップするシステムは必要ないですか。")}<br />
                        {t("ビジネスのスピードアップにオリジンAIを私たちが作成します。")}
                    </>}
                    src={"/images/Service_AI_Link.jpg"}
                    href="/ai"
                />
            </Box>

            <Box
                sx={{
                    width: "100%"
                }}
                mt={{
                    xs: 4,
                    sm: 6,
                    md: 8
                }}
                position="relative"
            >
                <ServiceCollectionItem
                    title={t("BIDnavi DATA")}
                    subTitle={t("ビジネス情報提供サービス")}
                    detail={<>
                        {t("リピーターの確保に。見込み客の獲得に。")}<br />
                        {t("ユーザーやビジネスに不可欠な情報を貴社のホームページに表示。")}<br />
                        {t("あらゆる士業の方、建設業、販売・サービス業に宝石のような情報を届けます。")}<br /><br />
                        {t("全く新しいマーケティングを貴社に。")}<br />
                        {t("自社サイトが一瞬で情報ポータルに生まれ変わります。")}
                    </>}
                    src={"/images/Service_Data.jpg"}
                    href="#"
                />
                <div
                    css={css({
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        margin: "auto",
                        display: "flex",
                        alignItems:"center",
                        justifyContent:"center",
                        fontSize:"1.8rem",
                        background: "rgba(70,70,70,0.58)"
                    })}
                >
                    COMMING SOON
                </div>
            </Box>

            <Box
                sx={{
                    width: "100%"
                }}
                mt={{
                    xs: 4,
                    sm: 6,
                    md: 8
                }}
            >
                <ServiceCollectionItem
                    title={t("LIVE")}
                    subTitle={t("LIVE配信システムパッケージ")}
                    detail={<>
                        {t("課金システム搭載のLIVEシステムが利用可能。")}<br />
                        {t("未来をもっと便利に、もっと実用的に。")}<br />
                    </>}
                    src={"/images/Service_Live.jpg"}
                    href="/streaming"
                />
            </Box>

            <Box
                sx={{
                    width: "100%"
                }}
                mt={{
                    xs: 4,
                    sm: 6,
                    md: 8
                }}
            >
                <ServiceCollectionItem
                    title={t("CG ＆ BIM")}
                    subTitle={t("CG ＆ BIM制作事業")}
                    detail={<>
                        {t("BIMデータがもたらすインパクトは建築プレゼンだけには収まらない。あらゆる建築業務の効率化に建築DXは欠かせません。")}<br />
                        {t("根幹を成すBIM移行サポート。そしてフォトリアルな映像をウォークスルーアプリで提供。")}<br />
                        {t("現実と見紛うCGがお客様に新しいUXをお届けします。")}
                    </>}
                    src={"/images/Service_CgBim.jpg"}
                    href="/cg-bim"
                />
            </Box>

            <Box
                sx={{
                    width: "100%"
                }}
                mt={{
                    xs: 4,
                    sm: 6,
                    md: 8
                }}
            >
                <ServiceCollectionItem
                    title={t("WEB APP")}
                    subTitle={t("WEBサイト開発事業")}
                    detail={<>
                        {t("AZUREを始めとするクラウドから様々な基幹システムをご提供。")}<br />
                        {t("2025年の崖回避には待ったなしの対応が必要です。")}<br />
                        {t("構築から運用マネジメントまで我々がサポートいたします。")}<br />
                        {t("見る、聞く、戯れる、")}<br />
                        {t("五感をくすぐるようなAPPや事業収益を向上させるサービスを提供します。")}
                    </>}
                    src={"/images/Service_WebApp.jpg"}
                    href="/webapp"
                />
            </Box>

            <Box
                sx={{
                    width: "100%"
                }}
                mt={{
                    xs: 4,
                    sm: 6,
                    md: 8
                }}
            >
                <ServiceCollectionItem
                    title={t("META VERSE")}
                    subTitle={t("メタバース事業")}
                    detail={<>
                        {t("ミラーワールドの概念はメタバースというキーワードへと変化しました。")}<br />
                        {t("どちらにしろ目指す先にあるのは物理世界とデジタル世界の高次元での融合。")}<br />
                        {t("「街」そのものを作り出すプラットフォームです。")}
                    </>}
                    src={"/images/Service_MirrorWorld.jpg"}
                    href="/mirrorworld"
                />
            </Box>

            <BIDnaviBunner />
        </main>
    </>;
};

const ServiceCollectionItem = (
    {
        title,
        subTitle,
        src,
        detail,
        href,
    }: {
        src: string,
        title: string,
        subTitle: string,
        detail: ReactNode,
        href: string,
    }
) => {
    const theme = useTheme();
    return (
        <ScrollTrigger once scrollStartOffset={"10%"}>
            {state =>
                <Box
                    sx={{
                        width: "100%"
                    }}
                    px={{
                        xs: 1,
                        sm: 2,
                        md: 3
                    }}
                    py={{
                        xs: 2,
                        sm: 0
                    }}
                    bgcolor={theme.palette.background.default}
                    textAlign="center"
                >
                    <Grid container
                        sx={{
                            width: "100%",
                            maxWidth: "1080px",
                            margin: "0 auto"
                        }}>
                        <Grid
                            item
                            xs={12}
                            sm={4}
                        >
                            <Box
                                p={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}
                                sx={{
                                    height: "100%"
                                }}
                                textAlign="left"
                                display="flex"
                                flexDirection="column"
                            >
                                <Box mt="auto">
                                    <SkewAnimation
                                        in={state === "entered"}
                                        bgcolor={theme.palette.background.default}
                                    >
                                        <Typography
                                            variant="h4"
                                            component="h2"
                                            sx={{
                                                fontFamily: "Jost"
                                            }}
                                        >{title}</Typography>
                                    </SkewAnimation>
                                </Box>
                                <Box mt={{
                                    xs: 1,
                                    sm: 2,
                                }}>
                                    <SkewAnimation
                                        in={state === "entered"}
                                        bgcolor={theme.palette.background.default}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            component="h3"
                                            sx={{
                                                letterSpacing: "0.2rem"
                                            }}
                                        >{subTitle}</Typography>
                                    </SkewAnimation>
                                </Box>
                                <Box
                                    mt={{
                                        xs: 0.5,
                                        sm: 1,
                                    }}
                                >
                                    <SkewAnimation
                                        in={state === "entered"}
                                        bgcolor={theme.palette.background.default}
                                    >
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >{detail}</Typography>
                                    </SkewAnimation>
                                </Box>
                                <Box
                                    mt={2}
                                    mb={{
                                        xs: 2,
                                        sm: "auto",
                                    }}>
                                    <ViewMoreButton
                                        href={href}
                                        subTitle="VIEW MORE"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={8}
                        >
                            <Box
                                px={{
                                    xs: 1,
                                    sm: 2,
                                    md: 3
                                }}
                                sx={{
                                    width: "100%",
                                    height: "100%"
                                }}
                            >
                                <FadeAndSlideAnimation
                                    in={state === "entered"}
                                    transform={{
                                        rotate: "0deg",
                                        translate: {
                                            x: "0px",
                                            y: "0px",
                                        },
                                        scale: 0.92,
                                    }}
                                    css={css`
                                        height: 100%;
                                    `}
                                >
                                    <TransitionImage
                                        src={src}
                                        alt={title}
                                        css={css`
                                            height: 100%;
                                        `}
                                    />
                                </FadeAndSlideAnimation>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            }
        </ScrollTrigger>
    );
};